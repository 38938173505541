export const WORDS_TOURNEY = [
  'inhouse',
  'inhouses',
  'pickups',
  'startgg',
  'liquipedia',
  'swiss',
  'trifecta',
  'ladder',
  'bracket',
  'bans',
  'central',
  'denver',
  'dallas',
  'westie',
  'eastie',

  'cecc',
  'arcadian',
  'pbwm',
  'octagon',
  'dreamhack',
  'cosco',
  'pbwm',
  'afreeca',
  'dubucup',
  'kgap',
  'osas',
  'nasl',
  'nasa',
  'liftoff',
  'landing',

  'vaughn',
  'brickbat',
  'saya',
  'achi',
  'mystic',
  'appabug',
  'vodible',
  'jackryan',
  'loven',
  'coaching',
  'surplu5',
  'blazing',
  'seraphim',
  'chikara',

  'orbitz',
  'quest',
  'sadbois',
  'comeback',
  'kings',
  'demons',
  'soondubu',
  'lyte',
  'surp',
  'tn4s',
  'prod',
  'best',
  'asop',
  'j7ng',
  'otku',
  'dubu',
  'goats',
  'bois',
  'rahh',
  'oshi',
  'wntr',
  'chatting',
  'otaku',
  'finesse',

  'corey',
  'g33ke',
  'lumost',
  'ginger',
  'yokie',
  'reznok',
  'daft',
  'loljss',
  'arcane',
  'chicken',
  'cast',
  'blufloo',
  'fwop',
  'fwopdwop',
  'gravity',
  'mira',
  'miragent',
  'slop',
  'lyarri',
  'lexus',
  'goomy',
  'caleb',
  'double',
  'gengur',
  'hook',
  'ignis',
  'j7ang',
  'leoleon',
  'mycup',
  'nakaaa',
  'stepi',
  'streak',
  'threezy',
  'venz',
  'barcode',
  'bells',
  'snake',
  'soren',
  'xenith',
  'bobadex',
  'borg',
  'kienoob',
  'kameron',
  'matty',
  'pengwy',
  'puffy',
  'snoball',
  'joker',
  'treddy',
  'wallaby',
  'yeesh',
  'yuume',
  'excellex',
  'breads',
  'grimella',
  'overflow',

  // osas
  'finale',
  'hubble',
  'kothem',
  'rosegold',
  'furret',
  'jujutsu',
  'cococrew',
  'antizen',
  'jobdoers',
  'winton',
  'mets',
  'nameless',
  'chikarmy',
  'tsuiseki',
  'game',
  'arcane',
  'chicken',
  'carly',
  'nina',
  'caspore',
  'zairei',
  'razy',
  'kfg12',
  'catkupo',
  'selinya',
  'chescaku',
  'zonseki',
  'aromatic',
  'ripstick',
  'doboom',
  'maxlc',
  'cloakoak',
  'marissa',
  'snoball',
  'vixx3y',
  'zidium',
  'vamp',
  'jdog',

  // kgap
  'numbat',
  'ajelly',
  'izanagi',
  'zesty',
  'ziddo',
  'hungee',
  'tsumie',
  'trajic',
  'mewwww',
  'nobiru',
  'daimond',
  'phyraa',
  'mrzerrtt',
  'mikemart',
  'claude',
  'catgpt3',
  'malachi',
  'monch0w0',
  'k20t00',

  // nasa
  'bozo',
  'prty',
  'quest',
  'raah',
  'noob',

  'dropleto',
  'forgot',
  'muncher',
  'switch',
  'nxzd',
  'kennklam',
  'maury',
  'soren',
  'zay4evr',
  'etir',
  'covvs',
  'droo',
  'bewm',
  'vaga101',

  // launch
  'bunn',
  'gard',
  'alex',
  'fudgy',
  'yoshi',
  'stormtft',
  'marcxo',
  'killiest',
  'apalme',
  'drew',
  'alexnder',
  'treddy',
  'hungryboi',
  'katsuna',
  'logan',
  'reburst',
  'bertrum',
  'zonseki',
  'spencer',

  'utdallas',
  'lakeland',
  'ucla',
  'njit',
  'stclair',
  'alberta',
  'rutgers',
  'lightsky',
  'xenith',
  'xkether',
  'coin',
  'zorro',
  'beepo',
  'amerats',
  'w1ndoge',
  'soren',
  'ethan',
  'mnnmouse',
  'jmpr',
  'melone',
  'apalme',
  'spartan',
  'vega',
  'w1ggly',
]
