export var VALID_ENTRANTS = [
'1uke',
'2ndfork',
'aaaaaaah',
'acaciaa',
'achi',
'acorn',
'adam2o2',
'aden',
'adolheid',
'aeros',
'afurri',
'aimmel',
'airvent',
'ajax',
'ajelly',
'ajskdl',
'akalanii',
'akimochi',
'alex2704',
'alexmere',
'alexnder',
'aliciaaa',
'aluc',
'amarilla',
'amerats',
'anacorn',
'anime',
'anonaysi',
'antc3519',
'apalme',
'apolleo',
'appabug',
'arcanum',
'arcovert',
'arctic',
'arkamii',
'arkasha',
'arkx',
'asiaman',
'atah',
'atgreat',
'atsuro',
'auvees',
'aylalira',
'aznazn',
'azurazet',
'babs2424',
'baddy',
'bagelman',
'baiacucu',
'bakaon',
'balex',
'balu',
'barcode',
'barron',
'barst850',
'barto',
'baumi',
'beba',
'bebao',
'beepo',
'bentup',
'bernie',
'bertrum',
'billup',
'birch',
'blals',
'blecko',
'blufloo',
'blurav',
'bobadex',
'bolbee',
'bonfir',
'borg',
'bortera',
'bosuhr',
'br1d0g',
'brickbat',
'btibo2',
'buggik',
'buhuble',
'buuchiz',
'calieltw',
'cards29',
'carex',
'carlyos',
'caspore',
'catboy',
'catgpt3',
'catkupo',
'catnipxd',
'celey',
'chargi',
'cheder',
'chescaku',
'chikara',
'chillphin',
'chouki',
'chribis',
'clichey',
'cloakoak',
'cloh',
'cng123',
'comet4k',
'conk',
'connax',
'corey',
'covvs',
'coyne',
'cranddy',
'cruoty',
'cruxleo',
'cryy',
'cuberto',
'd00d1e',
'd3damage',
'dadun',
'daemon0',
'daexen',
'daimond',
'dakcity',
'damma',
'darkpls',
'daru',
'dash',
'dashu',
'davied',
'dawoleq',
'deadduck',
'deadna',
'deathgun',
'deaxom',
'decrare',
'demoted',
'dendar',
'derevr',
'derrick',
'dinfar',
'diodaddy',
'dixon',
'dobble',
'doboom',
'doggitoz',
'donkers',
'donowall',
'doubleb03',
'dracocat',
'drander',
'dreg0',
'dro0',
'dropleto',
'drowzii',
'drozdak',
'duccup',
'ducky',
'duckyman',
'dunkie',
'duriana',
'dvmanxii',
'dyre',
'ecchix',
'echo008',
'eeveon',
'egulio',
'elkiies',
'elocxle',
'elusionz',
'emby!',
'emungus',
'enoc',
'eritole',
'ethan',
'etir',
'eunae',
'evwo',
'excellex',
'ezraftw',
'f2pwhale',
'failius',
'fatehin',
'feppy',
'fetta',
'fettsi',
'fierryy',
'filo',
'firo',
'fledd',
'floppyz',
'fmlfox',
'forlione',
'fouri',
'fozey',
'frozennq',
'frustt',
'fwopdwop',
'g0nard',
'g33ke',
'galaxia',
'game',
'gazale',
'gc47',
'gear5',
'gemmania',
'gengur',
'gillro',
'ginkyuu',
'giovanni',
'glaedry',
'gnjelly',
'godsent',
'goomy',
'gorf',
'gravity',
'greeeted',
'griar',
'grimella',
'gumby',
'gunner62',
'haethra',
'harpero',
'hatoka',
'hekuria',
'hennkar',
'heptagon',
'hoebow',
'hoishi',
'hoiste',
'hotty',
'hsquid',
'hvrn',
'iamjavi',
'ibbinx',
'iben',
'iceman95',
'idarui',
'ideality',
'ignis',
'imsalis',
'indelen',
'inxndo',
'isaah',
'isnack',
'itsgauss',
'itsxsly',
'izanagi',
'izzyboy',
'j7ang',
'jackryan',
'jadudes',
'jaydesun',
'jaylenwp',
'jdog',
'jesspag',
'jgslayz',
'jiiewser',
'jinxidf',
'jmany1',
'jmeta',
'jmpr',
'jodelj',
'joel020',
'jona',
'jonnyboy',
'jouann',
'joyrida',
'jstzy',
'justj4yy',
'juzio',
'jxssica',
'k0sh',
'k20t00',
'kagami',
'kaho',
'kaijux',
'kaitou',
'kaiwen',
'kaliilak',
'kameron',
'kamgotit',
'kamilla',
'kaminoai',
'kamui',
'kariiii',
'katsui13',
'katsuna',
'katypepe',
'kazumo',
'kazune27',
'kccasey',
'kdmos',
'kekle',
'kekle9',
'keltfire',
'kennklam',
'kensakii',
'kensi',
'keti',
'khamet',
'khionae',
'kidpan',
'kienoob',
'kilat',
'killiest',
'killmonk',
'kingexp',
'kingraz',
'kippen',
'kitsu',
'kitsune',
'kjewls',
'klaust',
'klore75',
'krimbus',
'kristo',
'kryllb',
'kryptic',
'kupo',
'kurohiro',
'kurriboh',
'kvgami',
'kylekilp',
'laillais',
'lakseru',
'lama',
'lamsu',
'lamtaco',
'layfire',
'lefiya',
'leoleon',
'leoo7',
'lgraceb',
'lightex',
'lightsky',
'lilkupo',
'lilybunn',
'locandro',
'lockoff',
'lokinb',
'loljss',
'lordnick',
'lsaid',
'ltligma',
'lukas441',
'lumost',
'lunchbox',
'luucy',
'lyarri',
'lylaeth',
'lync',
'lynx',
'maddcat',
'madlock',
'mafa47',
'magalar',
'magic14k',
'marcxo',
'marissa',
'maroooo',
'mathixd',
'mathsan',
'matyous',
'maury',
'maw2',
'max',
'maxalex',
'maxlc',
'maxqk74',
'mazzew',
'me10ne',
'medb',
'meekish',
'meelos',
'megunya',
'memeric',
'meowzi',
'merch4nt',
'mikemart',
'milopdf',
'minhprime',
'miragent',
'mirymeg',
'mkillz',
'mleon',
'mlgegg',
'mloom',
'mmleo',
'mnnmouse',
'moiwsh',
'monch0w0',
'monkhb',
'mood95',
'mook',
'motzy',
'moule',
'mrbiaze',
'mrclowns',
'mrflambo',
'mrhudat',
'mrman',
'mrsenyor',
'mrtorg',
'mrzerrtt',
'muffin',
'muki',
'munchi3',
'muryoku',
'mycup',
'mylkz',
'mystic',
'myuffin',
'nadaphii',
'nafoume',
'nagurofl',
'naioki',
'naj905',
'nakaaa',
'nakayia',
'nalaxelo',
'namesgok',
'nanoo',
'nasu',
'natty3',
'nayzos',
'nbad',
'neoplant',
'nerdoff',
'nero0407',
'nervah',
'nhaan',
'niju',
'nikurasu',
'nimsea',
'nishiyu',
'nitrun',
'nizmo',
'nohablo',
'notey',
'nozyenx',
'nrgdrink',
'nubsta',
'numbat',
'numbered',
'nunzy',
'nxzd',
'octico',
'octiq',
'ohkevvv',
'omeggers',
'oneka',
'oniheart',
'onique',
'oosifoo',
'orbnet',
'orcagirl',
'ouindoze',
'pafos',
'pandanin',
'pappas',
'paya',
'peeponut',
'pengwy',
'peruman',
'peterm',
'pgty',
'phreid',
'phyraa',
'pika5',
'piols',
'pizzahut',
'playdoc',
'playfade',
'potat',
'pr0wess',
'prizmo',
'profbean',
'psylux',
'pudash',
'pyrooni',
'qbko',
'qijacob',
'r1ven',
'r2r0',
'raeraq',
'rafbox',
'ragehand',
'ranko',
'raticus',
'ratmanr0b',
'raven11',
'razy',
'rdford13',
'red16',
'red45',
'redg1',
'redwitch',
'reedson',
'reezh',
'reinhart',
'rekkosed',
'remarcos',
'requill',
'restx',
'retrycx',
'rexalis',
'rexark',
'reznok',
'riftvs',
'rikatika',
'ripmcrib',
'ripstick',
'roanake',
'robocroc',
'ronp',
'rookie52',
'rort',
'runeloz',
'ryazo',
'ryft',
's1lent',
's4mm4r',
'sabre',
'sahiko',
'saikene',
'saje',
'salis',
'sandeity',
'sanimonz',
'sawcon',
'sbinke',
'schnuu',
'scirave',
'scmirad',
'selinya',
'sero',
'shakuu',
'sharkz',
'shine',
'shiro',
'shoto',
'shroy',
'si6x12',
'sigg',
'sihnez',
'silic0n',
'silky',
'simaz1',
'sirflops',
'sixdog',
'skowtee',
'skrskr',
'sleepun',
'slof',
'smardon',
'smellly',
'sn1p3r',
'snix',
'snny',
'snoball',
'snoogdy',
'snulge',
'softjamie',
'soren',
'sorroren',
'soydoma',
'spartan',
'sparx21',
'spencer',
'sphinxz',
'spooon',
'srskull',
'ss1lent',
'ssatiric',
'stepi',
'storm',
'stormtft',
'streak',
'sucy',
'sungold',
'sunward',
'surplu5',
'switch',
'sylvic',
'syther12',
'tazemany',
'tazzo',
'tb0ne',
'tbc56',
'tbone',
'tebb',
'teckirb',
'teisnowy',
'tejit',
'televol',
'tempax',
'temptr',
'tenfos',
'thecrabb',
'tiam',
'tibiano',
'tibixix',
'tikky',
'toe5',
'toinev2',
'tomotaco',
'tonher',
'trabje',
'train',
'trajiic',
'treddy',
'trick',
'trick69',
'tricki3',
'trikz30',
'trumpet',
'tsuiseki',
'tsukuz',
'tsumie',
'tuezdayz',
'tugh34',
'twisty',
'uberman',
'ughlee',
'umizoomi',
'userwet',
'vaddy',
'valirius',
'vane',
'vapedad',
'vaughn',
'vaur',
'vega',
'venz',
'vespukki',
'vid3o',
'vigol',
'vixx3y',
'void3d',
'volume',
'vore4',
'vrgl',
'wad',
'waif',
'walnut',
'watto',
'weebshii',
'weeeee',
'weirdo',
'wflakame',
'wh1sp',
'whisker',
'wiwy',
'wizard',
'wlungus',
'wo0k',
'woast221',
'wohat',
'wolfmang',
'woxee',
'wozatix',
'wwia7062',
'wìnk',
'xavrey',
'xelnius',
'xenith',
'xenium',
'xenockz',
'xhosta',
'xitrone',
'xmewie',
'xmoshii',
'xurya',
'xyvoid',
'yaelis',
'yarhar',
'yazo1',
'yeesh',
'yegee',
'yerffej',
'yetiman',
'ymsg',
'yokie',
'yuce',
'yugo',
'yummz',
'yuume',
'zairei',
'zarhynif',
'zeko',
'zenith',
'zesty',
'ziddo',
'zidium',
'zojalyx',
'zonseki',
'zorro',
'zuiy',
'zyoz',
]