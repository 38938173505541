export const WORDS_BASE = [
  'kais',
  'rune',
  'dubu',
  'juli',
  'juliette',
  'luna',
  'asher',
  'aimi',
  'juno',
  'estelle',
  'atlas',
  'drekar',
  'rasmus',
  'zentaro',
  'finii',
  'vyce',
  'octavia',
  'kazan',
  'mako',

  'app',
  'dais',
  'market',
  'gates',
  'onis',
  'ahten',
  'clarion',
  'taiko',
  'flipped',
  'pocket',
  'about',

  'omega',
  'obscura',
  'blobbo',
  'blobbos',
  'odyssey',
  'coredle',

  'cafe',
  'rose',
  'orbital',
  'gilded',
  'duboo',
  'nurse',

  'wall',
  'circle',
  'thorns',
  'nuke',
  'nukes',
  'nuked',
  'crater',
  'boost',
  'whammy',
  'whammies',
  'missile',
  'missiles',
  'rocket',
  'rockets',
  'banish',
  'banished',
  'barrage',
  'barrages',
  'firewall',
  'expanse',
  'expanses',
  'rollout',
  'flurry',
  'flurries',
  'maximus',
  'sentry',
  'drone',
  'lifeline',
  'whiplash',
  'hook',
  'umbrella',
  'iairush',
  'bassdrop',
  'flow',
  'chord',

  'stinger',
  'adren',
  'swagger',
  'stack',
  'stacks',
  'aerials',
  'bigfish',
  'cast',
  'chrono',
  'deadeye',
  'egoist',
  'hotshot',
  'monu',
  '12punch',
  'peak',
  'quick',
  'spark',
  'sparks',
  'timeless',
  'twin',
  'bulkup',
  'catalyst',
  'fireup',
  'demo',
  'reverb',
  'tempo',
  'unstop',
  'draft',
  'heavy',
  'surge',
  'dancer',
  'ponder',
  'gear',
  'slicks',
  'vambrace',
  'soles',
  'eject',
  'pauldron',
  'siphon',

  'clash',
  'clones',
  'omega',
  'chally',
  'diamond',
  'platinum',
  'plat',
  'gold',
  'silver',
  'bronze',
  'rookie',

  'strike',
  'strikes',
  'striking',
  'evade',
  'flip',
  'burst',
  'meter',
  'garfield',
  'orbs',
  'hits',
  'nets',
  'light',
  'medium',
  'heavy',
  'dash',
  'dashes',
  'jump',
  'jumps',
  'stagger',
  'haste',
  'creation',
  'buff',
  'buffs',
  'buffing',
  'heal',
  'heals',
  'healing',
  'ability',
  'primary',
  'special',
  'specials',
  'impact',
  'impacts',
  'ranged',
  'melee',
  'damage',
  'debuff',
  'debuffs',
  'power',
  'speed',
  'size',
  'blink',
  'blinks',
  'stun',
  'stuns',
  'immune',
  'elusive',
  'emote',
  'emotes',
  'flex',
  'midfield',
  'forward',
  'forwards',
  'goalie',
  'goalies',
  'cooldown',
  'takedown',
  'assist',
  'assists',
  'core',
  'cores',
  'bumpers',
  'pass',
  'passes',
  'passing',
  'save',
  'saves',
  'goal',
  'goals',
  'overtime',
  'striker',
  'strikers',
  'kill',
  'kills',
  'kick',
  'kicks',
  'rock',
  'puck',
  'brawler',
  'brawlers',
  'mirror',
  'mirrors',
  'laggy',
  'alts',
  'inter',

  // launch
]
