export var WORDS_DAILY = [
  'winton',
  'liftoff',
  'reznok',
  'tofucup',
  'monkey',
  'nork',
  'kitsune',
  'jackryan',
  'pbwm',
  'w1ndoge',
  'shockoso',
  'hbcat13',
  'mikemart',
  'goomy',
  'flipside',
  'matyous',
  'yami',
  'nxzd',
  'garfield',
  'chikara',
  'achiachi',
  'swmg',
  'elusive',
  'ogey',
  'sotere',
  'prty',
  'aimitank',
  'vyce',
  'banish',
  'inkys',
  'lobster',
  'surplu5',
  'clarion',
  'rekkosed',
  'ensnare',
  'bassdrop',
  'sonii',
  'chills',
  'striker',
  'appabug',
  'lockoff',
  'aesop',
  'virtuoso',
  'teuse',
  'playtest',
  'coredle',
  'foley',
  'osas',
  'octavia',
  'cordak',
  'lyarri',
  'goalie',
  'expanse',
  'bigba',
  'corelock',
  'ignited',
  'dropleto',
  'wiwi',
  'playbook',
  'zyoshi',
  'draft',
  'seatier',
  'xdme',
  'egoist',
  'kisu',
  'mirymeg',
  'blobbo',
  'maxnopea',
  'zentaro',
  'topspin',
  'mystic',
  'cringe',
  'gear',
  'breads',
  'washed',
]
