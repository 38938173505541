export var VALID_DISCORD = [
'achiachi',
'achichug',
'aliesse',
'bigba',
'blufloo',
'bosuhr',
'corey',
'dcap',
'dino24',
'dixon',
'ether',
'etir',
'fezu',
'fringer',
'gnomey',
'hbcat13',
'jamie',
'jinfra',
'kisu',
'konahri',
'letterb',
'linean',
'logan',
'lumi',
'lumi4312',
'lunchboy',
'maloz',
'mrzerrtt',
'nafanuwu',
'nameless',
'nibiria',
'nillun',
'obeeked',
'pkack',
'redsnow',
'samara',
'simp',
'smol',
'sucy',
'sunfish',
'teremina',
'tomaro',
'trpfy',
'unfitwit',
'xdme',
]