export const WORDS_NA = [
  'kbot',
  'nibiria',

  'cosco',
  'arcadian',
  'pbwm',
  'tuesday',
  'vaughn',
  'brickbat',
  'mystic',
  'saya',
  'achi',
  'appabug',
  'vodible',
  'surplu5',
  'blazing',

  'taco',
  'lazy',
  'corey',
  'xdme',
  'g33ke',
  'lumost',
  'yokie',
  'reznok',
  'daft',
  'loljss',
  'khionae',
  'arcane',
  'chicken',
  'boo20k',
  'trick',
  'fwop',
  'fwopdwop',
  'gravity',
  'slop',
  'lyarri',
  'lexus',
  'goomy',
  'caleb',
  'ignis',
  'kisu',
  'streak',
  'threezy',
  'appabug',
  'soren',
  'borg',
  'kienoob',
  'matty',
  'pengwy',
  'puffy',
  's1lent',
  'snoball',
  'kaiser',
  'joker',
  'treddy',
  'wallaby',
  'yeesh',
  'yuume',
  'excellex',
  'breads',
  'grimella',
  'overflow',

  // launch
]
